import React, { useState } from 'react';
import { Alert, Button, Card, CardContent, CardHeader } from 'ui-neumorphism';
import styled from 'styled-components';

import 'ui-neumorphism/dist/index.css';

const Question = styled.p`
    padding: 10px;
`;

const Answers = styled.div`
    padding: 10px;
    margin: 10px;
    display: flex;
    justify-content: space-evenly;
`;

const PageThree = ({ StartOver }) => {
    return (
        <CardContent>
            <CardHeader>Buy!</CardHeader>
            <Alert type={'success'} flat>Buy all the dips!</Alert>
            <Answers>
                <Button onClick={StartOver}>Start Over</Button>
            </Answers>
        </CardContent>
    );
};

const PageTwo = ({ StartOver }) => {
    return (
        <CardContent>
            <CardHeader>Sell!</CardHeader>
            <Alert type={'error'} flat>Hold or sell!</Alert>
            <Answers>
                <Button onClick={StartOver}>Start Over</Button>
            </Answers>
        </CardContent>
    );
};

const PageOne = ({ OnClickUp, OnClickDown }) => {
    return (
        <CardContent>
            <Question>Has the asset you're looking at been going up or down?</Question>
            <Answers>
                <Button onClick={OnClickUp}>Up</Button>
                <Button onClick={OnClickDown}>Down</Button>
            </Answers>
        </CardContent>
    );
};

const Container = styled.div`
    background-color: #eee;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
`;

export default function IndexPage() {
    const [ page, setPage ] = useState(0);

    const startOver = () => setPage(0);
    let currentPage = null, answerOne = null, answerTwo = null;
    switch (page) {
        case 2:
            currentPage = <PageThree StartOver={startOver} />;
            break;
        case 1:
            currentPage = <PageTwo StartOver={startOver} />;
            break;
        case 0:
        default:
            answerOne = () => setPage(1);
            answerTwo = () => setPage(2);
            currentPage = <PageOne OnClickUp={answerOne} OnClickDown={answerTwo} />;
            break;
    }

    return (
        <Container>
            <Card
                minWidth={330}
                maxWidth={800}
            >
                {currentPage}
            </Card>
        </Container>
    );
};